
              @import "@/assets/css/variables.scss";
            



































































































































































.language {
  display: flex;
  .lang-select {
    font-size: 20px;
    margin: 0 8px;
    cursor: pointer;
  }

  .text {
    color: $white;
  }
}

.lang-dropdown-box {
  padding: 0;
  border: 0;
  margin-top: 15px;
  background-color: transparent;

  .el-dropdown-menu__item {
    padding: 6px 8px !important;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:nth-last-child(2) {
      border-radius: 0 0 6px 6px;
    }

    img {
      width: 34px;
      @include rtl-sass-prop(margin-right, margin-left, 16px);
    }
  }

  /* 小箭头 */
  /deep/ {
    .popper__arrow {
      // @include rtl-sass-prop(left, right, 54px !important);
      @include rtl-sass-prop-dual(left, 108px !important, right, 54px !important);
    }

    .popper__arrow,
    .popper__arrow::after {
      border-bottom-color: #1c214f !important;
      @include rtl-sass-prop-sd(left, 0px !important);
    }
  }

  .el-dropdown-menu__item {
    line-height: normal;
    background-color: #1c214f;
    position: relative;

    span {
      font-size: 14px;
      padding-top: 3px;
      color: $white;
      display: inline-block;
      vertical-align: baseline;
    }

    &:not(.is-disabled):hover {
      background-color: $secondary;
    }

    &.active,
    &:focus {
      background-color: #04071c;
    }

    &.active,
    &:focus {
      span {
        color: $primary;
      }
    }
  }
}
