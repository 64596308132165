.wrapper {
  background: $background-color-base;
  height: 100vh;
  overflow: hidden;

  .wrapper-main {
    width: 100%;
    padding: 0 20px;
    background-color: $light-gray;
    overflow: hidden;
    min-height: calc(100vh - 74px - 80px);

    .wrapper-content {
      width: 100%;
      max-width: 1200px;
      padding-top: 35px;
      padding-bottom: 60px;
      margin: 0 auto;

      .contact-title {
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
      }

      h3 {
        font-size: 15px;
        color: $blue;
        line-height: 1.799;
      }
    }
  }
}

.fade-enter {
  opacity: 0.9;
}

.fade-leave {
  opacity: 0.9;
}

.fade-enter-active {
  transition: opacity 2s;
}

.fade-leave-active {
  transition: opacity 1s;
}

@mixin loading {
  display: inline-block;
  width: 150px;
  height: 153px;
  background: url($loading) no-repeat center;
  background-size: cover;
}

/** loading for star */
.client-portal-loading {
  @include loading;
}

/** loading for au */
.client-portal-loading-au {
  @include loading;
  animation: rotate 2s 1 linear;
  -moz-animation: rotate 2s infinite linear;
  -ms-animation: rotate 2s infinite linear;
  -webkit-animation: rotate 2s infinite linear;
  -o-animation: rotate 2s infinite linear;
}

#launcher {
  @include rtl-sass-prop(left, right, 0);
}
